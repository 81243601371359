import React from "react";
import {
  Sir,
  Jaje,
  Cvijet,
  Voce,
  Med,
  Meso,
  Povrce,
  Zitarice,
  Vino,
  Dostava,
} from "./Icons";

export const getIcon = (fill) => {
  return {
    Med: <Med fill={fill} />,
    "Jaja i tjestenine": <Jaje fill={fill} />,
    "Krumpir i žitarice": <Zitarice fill={fill} />,
    Povrće: <Povrce fill={fill} />,
    "Sirevi, vrhnje i mlijeko": <Sir fill={fill} />,
    "Cvijeće, sadnice i flanci": <Cvijet fill={fill} />,
    "Voće i voćni pripravci": <Voce fill={fill} />,
    "Vina, rakije i čajevi": <Vino fill={fill} />,
    Meso: <Meso fill={fill} />,
    Dostava: <Dostava fill={fill} />,
  };
};
