import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { svgColors } from "../../variables";
import { getIcon } from "../Utils/SVG";
import { handlePostRequest } from "../Utils/handle-requests";

const colors = {
  Med: "med-jaja-krumpir",
  "Jaja i tjestenine": "med-jaja-krumpir",
  "Krumpir i žitarice": "med-jaja-krumpir",
  Povrće: "povrce",
  "Sirevi, vrhnje i mlijeko": "sir",
  "Cvijeće, sadnice i flanci": "cvijece-voce",
  "Voće i voćni pripravci": "cvijece-voce",
  "Vina, rakije i čajevi": "vino-meso",
  Meso: "vino-meso",
};
const Categories = ({ history }) => {
  let { type } = useParams();
  const [data, setData] = React.useState([]);
  const [categories, setCategories] = React.useState([]);

  const getBackground = React.useCallback((name) => {
    const background = require(`../../assets/img/${name}`);
    return background;
  }, []);

  function insertBeforeLastOccurrence(strToSearch, strToFind, strToInsert) {
    var n = strToSearch.lastIndexOf(strToFind);
    if (n < 0) return strToSearch;
    return strToSearch.substring(0, n) + strToInsert + strToSearch.substring(n);
  }
  React.useEffect(() => {
    handlePostRequest(`${process.env.REACT_APP_URL}KategorijaGets`, []).then(
      (res) => {
        if (res) {
          setCategories(JSON.parse(res.data));
          handlePostRequest(
            `${process.env.REACT_APP_URL}ProizvodGetByKategorija`,
            [
              {
                kategorija: type,
              },
            ]
          )
            .then((res) => {
              setData(JSON.parse(res.data));
            })
            .catch((err) => console.log(err));
        }
      }
    );
  }, [type]);

  const getCategory = React.useCallback((category, categories) => {
    const obj = categories.find((obj) => obj.Naziv === category);
    return obj.SlikaRef;
  }, []);

  return (
    <>
      <div
        className="relative pt-24 pb-32 flex content-center items-center justify-center"
        style={{ minHeight: "50vh" }}
      >
        <div
          className="absolute top-0 w-full h-full bg-center bg-cover"
          style={{
            backgroundImage: `url(${
              categories.length !== 0 &&
              getBackground(
                insertBeforeLastOccurrence(
                  getCategory(type, categories),
                  ".",
                  "-min"
                )
              )
            })`,
          }}
        >
          <span
            id="blackOverlay"
            className="w-full h-full absolute opacity-25 bg-black"
          ></span>
        </div>
        <div className="container relative mx-auto">
          <div className="flex flex-wrap">
            <div className="w-full px-4 ml-auto mr-auto text-center ">
              <div className=" sm:pr-12">
                <h1 className="text-white font-semibold text-2xl md:text-4xl lg:text-6xl">
                  {type}
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div
          className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
          style={{ height: "70px", transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-gray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>
      <section className="bg-gray-200 pb-40 pt-16">
        <div className="container mx-auto flex flex-wrap">
          {data.length > 0 ? (
            data.map((item) => {
              return (
                <div
                  key={item.ID}
                  className="px-2 pb-10 w-full md:w-1/2 lg:w-1/3 "
                >
                  <div className="h-full">
                    <Box
                      onClick={() => history.push(`/prodavac/${item.ID}`)}
                      className={`flex flex-wrap self-center px-4 mx-auto max-w-sm h-full bg-gray-100 hover:bg-${colors[type]} shadow-md rounded pt-5 hover:shadow-xl cursor-pointer transition-all transition-2`}
                    >
                      <div className="w-full px-2 mb-4">
                        <div className="w-20 px-2 mb-4 mx-auto text-center">
                          {getIcon(svgColors[type])[type]}
                        </div>
                        <div className=" h-12">
                          <h2 className="text-3xl text-center">
                            {item.NazivOPG}
                          </h2>
                        </div>
                      </div>
                      {item.proizvodi.map((item) => {
                        return (
                          <React.Fragment key={item.Naziv}>
                            <div className="w-2/4 px-2 mb-4 text-l">
                              <div className=" h-6">
                                <p>{item.Naziv}</p>
                              </div>
                            </div>
                            <div className="w-2/4 px-2 mb-4 text-sm">
                              <div className="h-6 text-right">
                                <p>
                                  Cijena: {item.Cijena} HRK / {item.Mjera}
                                </p>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                      <div className="w-3/4 px-2 mb-4">
                        <div className=" h-6"></div>
                      </div>
                    </Box>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="flex justify-between flex-wrap w-full">
              {[1, 2, 3, 4, 5, 6].map((item) => {
                return (
                  <div
                    key={item}
                    className="px-2 pb-10 w-full  md:w-1/2 lg:w-1/3"
                  >
                    <div>
                      <div className="flex flex-wrap self-center mx-auto max-w-sm bg-gray-400 shadow-md rounded pt-5">
                        <div className="w-full px-2 mb-4">
                          <div className="skeleton-box bg-gray-600 h-12"></div>
                        </div>
                        <div className="w-2/3 px-2 mb-4">
                          <div className="skeleton-box bg-gray-600 h-6"></div>
                        </div>
                        <div className="w-1/3 px-2 mb-4">
                          <div className="skeleton-box bg-gray-500 h-6"></div>
                        </div>
                        <div className="w-3/4 px-2 mb-4">
                          <div className="skeleton-box bg-gray-600 h-6"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </section>
    </>
  );
};
export default Categories;

const Box = styled.div`
  &:hover {
    transition: 0.3s;
    h2,
    p,
    i {
      color: white;
      text-shadow: 2px 2px 2px #a3a3a3;
    }
    svg {
      g {
        path {
          fill: white !important;
        }
      }
    }
  }
`;
