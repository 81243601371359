import axios from "axios";

const instance = axios.create();

instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    /*   if (response.data.length) {
      handlePostRequest(response.config.url, [response.config.data])
        .then((res) => res)
        .catch((err) => console.log(err));
    } else {
      return response;
    } */
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    //replace with currentUser.username later
    if (error.response.status === 500) {
      handlePostRequest(`${process.env.REACT_APP_URL}Load`, [])
        .then((res) => {
          sessionStorage.setItem("isLoaded", res.data);
          handlePostRequest(error.config.url, error.config.data);
        })
        .catch((err) => console.log(err));
    }
    /*  instance
      .post(
        "http://139.59.206.191:8080/http://accountant.plc.hr/Place/Unload",
        { user: "igor" }
      )
      .then(res => {
        window.location.reload(true);
      }); */
    return Promise.reject(error);
  }
);

export function handlePostRequest(url, options, token) {
  return instance.post(url, ...options, {
    headers: {
      Authorization: `JWT ${token}`,
    },
  });
}

export function handleGetRequest(url, token, type) {
  return instance.get(url, {
    headers: { Authorization: `JWT ${token}` },
    responseType: type,
  });
}
