import React from "react";
import {
  useForm,
  useFieldArray,
  Controller,
  ErrorMessage,
} from "react-hook-form";
import { handlePostRequest } from "../Utils/handle-requests";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import { Select } from "./UtilComponents";
import { five, ten } from "./variables";

const NewPost = ({ categories }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [message, setMessage] = React.useState({ state: false, msg: "" });
  const { register, control, handleSubmit, errors, reset } = useForm({
    defaultValues: {
      Suglasnost: false,
      proizvodi: [{ Naziv: "", Mjera: "", Cijena: "" }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "proizvodi",
  });

  const submit = (data) => {
    setIsLoading(true);
    const p = {
      ID: 0,
      NazivOPG: data.NazivOPG,
      Kontakt: data.Kontakt,
      Mobitel: data.Mobitel,
      Grad: data.Grad,
      PostanskiBroj: Number(data.PostanskiBroj),
      Adresa: data.Adresa,
      Email: data.Email,
      Dostava: data.Dostava,
      Napomena: data.Napomena,
      Facebook: data.Facebook,
      Webstranica: data.WebStranica,
      Suglasnost: data.Suglasnost,
      UpdateTime: new Date(),
      CreatedByUser: "",
      proizvodi: [],
    };
    const proiz = data.proizvodi.map((item, index) => {
      return {
        ...item,
        ID: 0,
        ProdavacID: 2,
        Kategorija: item.Kategorija,
        Cijena: Number(item.Cijena.replace(" HRK", "").replace(",", ".")),
        UpdateTime: new Date(),
        CreatedByUser: "",
      };
    });
    handlePostRequest(`${process.env.REACT_APP_URL}ProdavacAddEdit`, [
      {
        p: p,
        ps: proiz,
      },
    ])
      .then((res) => {
        if (res.data === "True") {
          setIsLoading(false);
          reset({
            Suglasnost: false,
            proizvodi: [{ Naziv: "", Mjera: "", Cijena: "" }],
          });
          setMessage({ state: true, msg: "Vaš unos je spremljen." });
          setTimeout(() => {
            setMessage({ state: false, msg: "" });
          }, 2500);
        } else if (res.data === "False") {
          setMessage({ state: false, msg: "Nešto nije u redu" });
          setTimeout(() => {
            setMessage({ state: false, msg: "" });
          }, 2500);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <form
        className="container relative pt-20 mx-auto flex flex-wrap flex-col justify-center"
        onSubmit={handleSubmit(submit)}
      >
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
                <div className="flex-auto p-5 lg:p-10">
                  <h4 className="text-2xl font-semibold">Ispunite podatke</h4>
                  <div className="px-2 mt-6">
                    <div className="flex flex-wrap max-w-4xl mx-auto">
                      <div className="w-full sm:w-6/12 px-2 mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="NazivOPG"
                        >
                          Naziv OPG-a ili tvrtke*
                        </label>
                        <input
                          name="NazivOPG"
                          type="text"
                          className="w-full px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                          placeholder="Naziv OPG-a ili tvrtke"
                          style={{ transition: "all 0.15s ease 0s" }}
                          defaultValue=""
                          ref={register({ required: "Naziv je obvezan" })}
                        />
                        <ErrorMessage
                          as={Error}
                          errors={errors}
                          name="NazivOPG"
                        />
                      </div>
                      <div className="w-full sm:w-6/12 px-2 mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="Kontakt"
                        >
                          Kontakt osoba*
                        </label>
                        <input
                          name="Kontakt"
                          type="text"
                          className="w-full px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                          placeholder="Kontakt osoba"
                          style={{ transition: "all 0.15s ease 0s" }}
                          defaultValue=""
                          ref={register({
                            required: "Kontakt osoba je obvezna",
                          })}
                        />
                        <ErrorMessage
                          as={Error}
                          errors={errors}
                          name="Kontakt"
                        />
                      </div>
                      <div className="w-full sm:w-6/12 px-2 mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="Mobitel"
                        >
                          Mobitel*
                        </label>
                        <input
                          name="Mobitel"
                          type="text"
                          className="w-full px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                          placeholder="Mobitel"
                          style={{ transition: "all 0.15s ease 0s" }}
                          defaultValue=""
                          ref={register({ required: "Mobitel je obvezan" })}
                        />
                        <ErrorMessage
                          as={Error}
                          errors={errors}
                          name="Mobitel"
                        />
                      </div>
                      <div className="w-full sm:w-6/12 px-2 mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="Grad"
                        >
                          Grad*
                        </label>
                        <input
                          name="Grad"
                          type="text"
                          className="w-full px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                          placeholder="Grad"
                          style={{ transition: "all 0.15s ease 0s" }}
                          defaultValue=""
                          ref={register({ required: "Grad je obvezan" })}
                        />
                        <ErrorMessage as={Error} errors={errors} name="Grad" />
                      </div>
                      <div className="w-full sm:w-6/12 px-2 mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="PostanskiBroj"
                        >
                          Poštanski broj*
                        </label>
                        <Controller
                          as={
                            <NumberFormat
                              type="text"
                              autoComplete="off"
                              allowLeadingZeros={true}
                              isAllowed={(values) => {
                                const { formattedValue, floatValue } = values;
                                return (
                                  formattedValue === "" || floatValue <= 99999
                                );
                              }}
                              decimalScale={0}
                            />
                          }
                          className="w-full px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                          style={{ transition: "all 0.15s ease 0s" }}
                          control={control}
                          name="PostanskiBroj"
                          placeholder="Postanski broj"
                          defaultValue=""
                          rules={{
                            required: "Poštanski broj je obvezan",
                            minLength: {
                              value: 5,
                              message:
                                "Poštanski broj mora sadržavati najmanje 5 znakova",
                            },
                            maxLength: {
                              value: 5,
                              message:
                                "Poštanski broj mora sadržavati najviše 5 znakova",
                            },
                          }}
                        />

                        {/*  <input
                          type="text"
                          className="w-full px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                          style={{ transition: "all 0.15s ease 0s" }}
                          name="PostanskiBroj"
                          placeholder="Postanski broj"
                          defaultValue=""
                          ref={register({
                            required: "Poštanski broj je obvezan",
                          })}
                        /> */}
                        <ErrorMessage
                          as={Error}
                          errors={errors}
                          name="PostanskiBroj"
                        />
                      </div>
                      <div className="w-full sm:w-6/12 px-2 mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="Adresa"
                        >
                          Adresa*
                        </label>
                        <input
                          name="Adresa"
                          type="text"
                          className="w-full px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                          placeholder="Adresa"
                          style={{ transition: "all 0.15s ease 0s" }}
                          defaultValue=""
                          ref={register({ required: "Adresa je obvezna" })}
                        />
                        <ErrorMessage
                          as={Error}
                          errors={errors}
                          name="Adresa"
                        />
                      </div>
                      <div className="w-full sm:w-6/12 px-2 mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="Dostava"
                        >
                          Dostava*
                        </label>
                        <input
                          name="Dostava"
                          type="text"
                          className="w-full px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                          placeholder="Dostava"
                          style={{ transition: "all 0.15s ease 0s" }}
                          defaultValue=""
                          ref={register({
                            required: "Dostava je obvezno polje",
                          })}
                        />
                        <ErrorMessage
                          as={Error}
                          errors={errors}
                          name="Dostava"
                        />
                      </div>
                      <div className="w-full sm:w-6/12 px-2 mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="Email"
                        >
                          Email
                        </label>
                        <input
                          name="Email"
                          type="text"
                          className="w-full px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                          placeholder="Email"
                          style={{ transition: "all 0.15s ease 0s" }}
                          defaultValue=""
                          ref={register({
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "Email adresa nije ispravna",
                            },
                          })}
                        />
                        <ErrorMessage as={Error} errors={errors} name="Email" />
                      </div>
                      <div className="w-full sm:w-6/12 px-2 mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="Facebook"
                        >
                          Facebook
                        </label>
                        <input
                          name="Facebook"
                          type="text"
                          className="w-full px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                          placeholder="Facebook"
                          style={{ transition: "all 0.15s ease 0s" }}
                          defaultValue=""
                          ref={register()}
                        />
                      </div>
                      <div className="w-full sm:w-6/12 px-2 mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="WebStranica"
                        >
                          Web stranica
                        </label>
                        <input
                          name="WebStranica"
                          type="text"
                          className="w-full px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                          placeholder="Web stranica"
                          style={{ transition: "all 0.15s ease 0s" }}
                          defaultValue=""
                          ref={register()}
                        />
                      </div>

                      <div className="w-full  px-2 mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="Grad"
                        >
                          Napomena
                        </label>
                        <textarea
                          name="Napomena"
                          rows="3"
                          type="text"
                          className="w-full px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                          placeholder="Napomena"
                          style={{ transition: "all 0.15s ease 0s" }}
                          defaultValue=""
                          ref={register()}
                        />
                        <ErrorMessage
                          as={Error}
                          errors={errors}
                          name="Napomena"
                        />
                      </div>
                      <div className="w-full px-2 mb-3 ">
                        <div className="bg-white rounded px-1">
                          <label className="w-full">
                            <input
                              className="mr-2 leading-tight"
                              type="checkbox"
                              name="Suglasnost"
                              ref={register({ required: "Morate potvrditi" })}
                            />
                            <span className="text-sm">
                              Ovim putem dajem privolu Općini Pitomača za
                              prikupljanje i obrađivanje osobnih podataka u
                              svrhu prodaje proizvoda putem aplikacije Pitomački
                              pijac, kao i procedure koja ga obuhvaća, a
                              sukladno Općom uredbom o zaštiti osobnih podataka
                              EU 2016/679 (GDPR) i Zakonom o provedbi Opće
                              uredbe o zaštiti osobnih podataka (NN 42/18).
                            </span>
                          </label>
                        </div>
                        <ErrorMessage
                          as={Error}
                          errors={errors}
                          name="Suglasnost"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-2 mt-6">
                    <h2 className="text-2xl mb-6">Proizvodi</h2>
                    {fields.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="flex flex-wrap max-w-4xl mx-auto border-2 border-gray-600 rounded p-2 mb-4 sm:mb-0 sm:border-none sm:p-0"
                        >
                          <div className="w-full sm:w-6/12 px-2 mb-3">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="Naziv"
                            >
                              Naziv
                            </label>
                            <input
                              name={`proizvodi[${index}].Naziv`}
                              defaultValue={`${item.Naziv}`}
                              ref={register({
                                required: "Naziv je obvezan",
                              })}
                              type="text"
                              className="w-full px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                              placeholder="Naziv"
                              style={{ transition: "all 0.15s ease 0s" }}
                            />
                            <ErrorMessage
                              as={Error}
                              errors={errors}
                              name={`proizvodi[${index}].Naziv`}
                            />
                          </div>
                          <div className="w-full sm:w-6/12 px-2 mb-3">
                            <Select
                              name={`proizvodi[${index}].Kategorija`}
                              label="Kategorija"
                              id={`proizvodi[${index}].Kategorija`}
                              defaultValue=""
                              register={register({
                                required: "Kategorija je obvezna",
                              })}
                              options={categories}
                            ></Select>

                            <ErrorMessage
                              as={Error}
                              errors={errors}
                              name={`proizvodi[${index}].Kategorija`}
                            />
                          </div>
                          <div className="w-full sm:w-6/12 px-2 mb-3">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="Mjera"
                            >
                              Mjerna jedinica
                            </label>
                            <input
                              name={`proizvodi[${index}].Mjera`}
                              defaultValue={`${item.Mjera}`}
                              ref={register({
                                required: "Mjerna jedinica je obvezna",
                              })}
                              type="text"
                              className="w-full px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                              placeholder="Mjerna jedinica"
                              style={{ transition: "all 0.15s ease 0s" }}
                            />
                            <ErrorMessage
                              as={Error}
                              errors={errors}
                              name={`proizvodi[${index}].Mjera`}
                            />
                          </div>
                          <div className="w-full sm:w-6/12 px-2 mb-3">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="Cijena"
                            >
                              Cijena
                            </label>
                            <Controller
                              as={
                                <NumberFormat
                                  decimalScale={2}
                                  label="Cijena"
                                  fixedDecimalScale={true}
                                  min={0}
                                  autoComplete="off"
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  suffix=" HRK"
                                />
                              }
                              placeholder="Cijena"
                              className="w-full px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                              style={{ transition: "all 0.15s ease 0s" }}
                              name={`proizvodi[${index}].Cijena`}
                              control={control}
                              defaultValue={`${item.Cijena}`}
                              rules={{
                                required: "Cijena je obvezna",
                                min: {
                                  value: 1,
                                  message: "Minimalan iznos je 1",
                                },
                              }}
                            />
                            <ErrorMessage
                              as={Error}
                              errors={errors}
                              name={`proizvodi[${index}].Cijena`}
                            />
                          </div>
                          <div className="w-full px-2 mb-0 flex h-16 first:px20">
                            <button
                              className="mx-auto mb-3 hover:shadow-md hover:bg-gray-200 rounded-full w-12-2 "
                              type="button"
                              onClick={() => remove(index)}
                            >
                              <i className="fas fa-trash-alt text-red-600"></i>
                            </button>
                          </div>
                        </div>
                      );
                    })}
                    <section className="flex justify-center mx-auto">
                      <button
                        className="bg-green-500 text-white shadow p-2 px-5 mr-3 mt-5 rounded"
                        type="button"
                        onClick={() =>
                          append({ Naziv: "", Mjera: "", Cijena: "" })
                        }
                      >
                        Dodaj 1
                      </button>
                      <button
                        className="bg-green-500 text-white shadow p-2 px-5 mr-3 mt-5 rounded"
                        type="button"
                        onClick={() => append(five)}
                      >
                        Dodaj 5
                      </button>
                      <button
                        className="bg-green-500 text-white shadow p-2 px-5 mr-3 mt-5 rounded"
                        type="button"
                        onClick={() => append(ten)}
                      >
                        Dodaj 10
                      </button>
                    </section>
                  </div>
                  <div className="text-center mt-6">
                    <Button
                      disabled={isLoading}
                      className="bg-gray-900 flex text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none  mb-1 mx-auto"
                      type="submit"
                      style={{ transition: "all 0.15s ease 0s" }}
                    >
                      {isLoading ? (
                        <>
                          <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-6 w-6"></div>
                          <span className="ml-2">Slanje...</span>{" "}
                        </>
                      ) : (
                        "Potvrdi"
                      )}
                    </Button>
                  </div>
                  {message.msg.length > 1 && (
                    <div
                      className={`bg-${
                        message.state ? "green" : "red"
                      }-100 w-full text-${
                        message.state ? "green" : "red"
                      }-500 border-l-8 border-${
                        message.state ? "green" : "red"
                      }-500 p-4`}
                    >
                      <p className="font-bold">
                        {message.state ? "Slanje uspjelo!" : "Greška"}
                      </p>
                      <p>{message.msg}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default NewPost;
const Error = styled.p`
  margin: 5px 0;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: rgb(244, 67, 54);
`;
const Button = styled.button`
  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }
`;
