import React from "react";
export const Sir = ({ fill }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1835 1835"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
  >
    <g transform="matrix(1.33333,0,0,1.33333,-416.125,-416.122)">
      <g>
        <g transform="matrix(18.9416,0,0,18.9416,122.853,-2298.95)">
          <path
            d="M20.628,148.484C34.811,134.301 57.806,134.301 71.988,148.484C86.171,162.667 86.171,185.661 71.988,199.845C57.806,214.027 34.811,214.027 20.628,199.845C6.445,185.661 6.445,162.667 20.628,148.484Z"
            style={{
              fill: fill,
              fillOpacity: "0.38",
              fillRule: "nonzero",
            }}
          />
        </g>
        <g transform="matrix(2.60333,0,0,2.60333,516.316,480.508)">
          <path
            d="M10.512,156.385L241.676,192.032C239.897,188.454 238.807,184.478 238.807,180.215C238.807,165.449 250.769,153.487 265.54,153.487C280.308,153.487 292.273,165.449 292.273,180.215C292.273,187.388 289.412,193.872 284.807,198.681L360.199,210.303C368.951,211.654 372.831,206.324 368.859,198.397L333.458,127.564C329.286,130.251 324.333,131.854 318.999,131.854C304.231,131.854 292.273,119.886 292.273,105.121C292.273,93.63 299.563,83.908 309.746,80.141L292.317,45.316C288.362,37.396 278.536,33.769 270.377,37.223L232.122,53.41C237.244,60.429 240.297,69.041 240.297,78.39C240.297,101.853 221.282,120.867 197.807,120.867C176.83,120.867 159.442,105.645 156.003,85.644L9.43,147.683C1.28,151.141 1.759,155.032 10.512,156.385ZM125.661,123.215C130.544,123.215 134.499,127.165 134.499,132.048C134.499,136.931 130.544,140.881 125.661,140.881C120.788,140.881 116.828,136.932 116.828,132.048C116.829,127.165 120.788,123.215 125.661,123.215Z"
            style={{ fill: fill, fillRule: "nonzero" }}
          />
          <path
            d="M30.294,218.466C47.306,218.466 61.093,232.259 61.093,249.271C61.093,266.267 47.305,280.068 30.294,280.068C23.85,280.068 17.875,278.081 12.93,274.694L15.978,301.055C16.998,309.867 24.989,317.417 33.837,317.93L222.861,329.087C222.793,328.333 222.632,327.611 222.632,326.847C222.632,312.203 234.489,300.35 249.125,300.35C263.768,300.35 275.622,312.204 275.622,326.847C275.622,328.662 275.43,330.442 275.093,332.16L334.179,335.646C343.023,336.16 351.519,329.531 353.158,320.819L371.589,222.529L0,162.851L7.598,228.508C13.229,222.353 21.297,218.466 30.294,218.466ZM135.451,219.127C145.734,219.127 154.063,227.458 154.063,237.749C154.063,248.036 145.726,256.368 135.451,256.368C125.158,256.368 116.828,248.036 116.828,237.749C116.829,227.458 125.158,219.127 135.451,219.127ZM98.206,271.243C103.091,271.243 107.05,275.19 107.05,280.072C107.05,284.957 103.091,288.904 98.206,288.904C93.335,288.904 89.375,284.957 89.375,280.072C89.375,275.19 93.335,271.243 98.206,271.243Z"
            style={{ fill: fill, fillRule: "nonzero" }}
          />
        </g>
      </g>
    </g>
  </svg>
);
export const Jaje = ({ fill }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1835 1835"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
  >
    <g transform="matrix(1.33333,0,0,1.33333,-416.125,-416.122)">
      <g>
        <g transform="matrix(18.9416,0,0,18.9416,122.853,-2298.95)">
          <path
            d="M20.628,148.484C34.811,134.301 57.806,134.301 71.988,148.484C86.171,162.667 86.171,185.661 71.988,199.845C57.806,214.027 34.811,214.027 20.628,199.845C6.445,185.661 6.445,162.667 20.628,148.484Z"
            style={{
              fill: fill,
              fillOpacity: "0.38",
              fillRule: "nonzero",
            }}
          />
        </g>
        <g transform="matrix(3.90549,0,0,3.90549,537.361,501.552)">
          <path
            d="M175.849,49.544C157.617,18.521 136.163,0 118.458,0C100.598,0 79.083,18.523 60.908,49.551C43.152,79.864 32.55,115.089 32.55,143.775C32.55,173.102 41.252,197.28 57.717,213.697C72.952,228.888 93.956,236.918 118.458,236.918C142.96,236.918 163.965,228.889 179.2,213.697C195.664,197.28 204.367,173.102 204.367,143.775C204.368,115.156 193.707,79.93 175.849,49.544Z"
            style={{
              fill: fill,
              fillOpacity: "0.94",
              fillRule: "nonzero",
            }}
          />
        </g>
      </g>
    </g>
  </svg>
);
export const Cvijet = ({ fill }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1835 1835"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
  >
    <g transform="matrix(1.33333,0,0,1.33333,-416.125,-416.122)">
      <g>
        <g transform="matrix(18.9416,0,0,18.9416,122.853,-2298.95)">
          <path
            d="M20.628,148.484C34.811,134.301 57.806,134.301 71.988,148.484C86.171,162.667 86.171,185.661 71.988,199.845C57.806,214.027 34.811,214.027 20.628,199.845C6.445,185.661 6.445,162.667 20.628,148.484Z"
            style={{
              fill: fill,
              fillOpacity: "0.38",
              fillRule: "nonzero",
            }}
          />
        </g>
        <g
          id="XMLID_1441_"
          transform="matrix(2.01186,0,0,2.01186,532.146,532.146)"
        >
          <path
            d="M391.437,138.084C385.108,135.902 378.103,138.177 374.263,143.658L247.548,324.541L247.548,233.632C297.038,226.351 335.151,183.61 335.151,132.128L335.151,15C335.151,9.2 331.808,3.921 326.566,1.441C321.323,-1.038 315.123,-0.276 310.637,3.404L264.273,41.439L244.273,16.376C241.426,12.81 237.112,10.732 232.548,10.732C227.985,10.732 223.67,12.809 220.823,16.376L200.823,41.439L154.459,3.403C149.976,-0.275 143.773,-1.038 138.531,1.44C133.289,3.92 129.946,9.2 129.946,14.999L129.946,132.127C129.946,183.608 168.058,226.35 217.548,233.631L217.548,324.54L90.833,143.658C86.993,138.177 79.988,135.901 73.659,138.084C67.332,140.265 63.215,146.373 63.569,153.057C63.686,155.267 66.674,207.873 87.751,268.657C100.243,304.682 116.544,336.107 136.2,362.061C158.982,392.144 186.321,414.867 217.548,429.778L217.548,450.096C217.548,458.38 224.264,465.096 232.548,465.096C240.832,465.096 247.548,458.38 247.548,450.096L247.548,429.778C278.775,414.866 306.114,392.143 328.897,362.061C348.553,336.107 364.854,304.681 377.345,268.657C398.423,207.873 401.41,155.266 401.528,153.057C401.88,146.373 397.764,140.265 391.437,138.084Z"
            style={{ fill: fill, fillRule: "nonzero" }}
          />
        </g>
      </g>
    </g>
  </svg>
);

export const Voce = ({ fill }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1835 1835"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
  >
    <g transform="matrix(1.33333,0,0,1.33333,-406.849,-408.244)">
      <g>
        <g transform="matrix(18.9416,0,0,18.9416,115.896,-2304.85)">
          <path
            d="M20.628,148.484C34.811,134.301 57.806,134.301 71.988,148.484C86.171,162.667 86.171,185.661 71.988,199.845C57.806,214.027 34.811,214.027 20.628,199.845C6.445,185.661 6.445,162.667 20.628,148.484Z"
            style={{
              fill: fill,
              fillOpacity: "0.38",
              fillRule: "nonzero",
            }}
          />
        </g>
        <g transform="matrix(2.73631,0,0,2.73631,597.704,589.693)">
          <path
            d="M235.119,86.109C206.493,74.079 185.626,81.804 170.277,89.099C163.763,92.195 159.067,94.583 155.351,96.278C156.95,83.889 161.812,71.85 169.966,60.287C180.781,44.949 193.542,36.2 193.67,36.114C198.239,33.032 199.454,26.831 196.381,22.252C193.304,17.665 187.089,16.444 182.505,19.521C181.878,19.942 167.006,30.031 154.063,48.14C151.19,52.16 148.64,56.264 146.403,60.436C144.5,56.864 142.444,53.771 140.316,51.096C145.378,34.785 135.911,16.232 117.956,8.455C85.13,-5.755 49.281,0.635 25.701,8.124C22.758,9.066 20.243,12.256 19.607,15.898C18.971,19.531 20.377,22.693 23.009,23.546C40.75,29.321 66.224,42.046 91.358,69.944C101.378,81.08 116.884,77.38 127.934,68.429C131.152,73.974 136.647,85.545 135.194,96.744C131.319,95.032 126.351,92.481 119.204,89.087C103.861,81.801 83.011,74.076 54.38,86.107C19.19,100.897 9.407,157.502 32.536,212.536C55.663,267.57 102.941,300.185 138.141,285.397C138.145,285.395 138.15,285.393 138.154,285.391C142.375,283.621 147.131,283.621 151.35,285.392C151.354,285.394 151.358,285.395 151.362,285.397C186.557,300.185 233.842,267.57 256.967,212.536C280.091,157.502 270.316,100.897 235.119,86.109ZM94.255,112.464C74.471,112.464 58.375,128.56 58.375,148.344C58.375,152.487 55.018,155.844 50.875,155.844C46.732,155.844 43.375,152.487 43.375,148.344C43.375,120.288 66.199,97.464 94.255,97.464C98.398,97.464 101.755,100.821 101.755,104.964C101.755,109.107 98.398,112.464 94.255,112.464Z"
            style={{ fill: fill, fillRule: "nonzero" }}
          />
        </g>
      </g>
    </g>
  </svg>
);
export const Med = ({ fill }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1835 1835"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
  >
    <g transform="matrix(1.33333,0,0,1.33333,-416.125,-416.122)">
      <g>
        <g transform="matrix(18.9416,0,0,18.9416,122.853,-2298.95)">
          <path
            d="M20.628,148.484C34.811,134.301 57.806,134.301 71.988,148.484C86.171,162.667 86.171,185.661 71.988,199.845C57.806,214.027 34.811,214.027 20.628,199.845C6.445,185.661 6.445,162.667 20.628,148.484Z"
            style={{
              fill: fill,
              fillOpacity: "0.38",
              fillRule: "nonzero",
            }}
          />
        </g>
        <g transform="matrix(2.72173,0,0,2.72173,570.738,570.74)">
          <path
            d="M278.665,55.78L278.665,23.037C278.665,15.765 272.758,9.858 265.475,9.858L44.622,9.858C37.35,9.858 31.443,15.765 31.443,23.037L31.443,55.671L0,145.965L12.858,135.201C12.967,135.119 23.426,126.455 36.6,120.14L36.6,290.72C36.6,298.911 43.268,305.574 51.454,305.574L260.51,305.574C268.712,305.574 275.364,298.906 275.364,290.72L275.364,143.556C295.053,134.658 310.93,125.194 311.604,124.791L315.433,122.496L278.665,55.78ZM51.454,296.323C48.37,296.323 45.857,293.81 45.857,290.726L45.857,277.487L56.724,296.322C56.724,296.323 51.454,296.323 51.454,296.323ZM89.886,295.975L61.793,295.975L47.739,271.63L61.794,247.301L89.887,247.301L103.936,271.63L89.886,295.975ZM47.739,212.708L61.794,188.379L89.887,188.379L103.936,212.708L89.887,237.037L61.793,237.037L47.739,212.708ZM141.117,266.615L113.019,266.615L98.97,242.286L113.019,217.952L141.117,217.952L155.161,242.286L141.117,266.615ZM218.852,150.431C212.216,150.431 207.37,148.903 204.477,145.884C201.67,142.936 200.365,138.226 200.648,131.857C201.225,119.157 199.196,110.579 194.475,105.641C191.587,102.622 187.856,101.094 183.401,101.094C166.067,101.094 142.178,125.668 139.481,128.501L139.095,128.952C134.983,134.679 121.837,149.517 110.567,149.517C103.355,149.517 97.345,142.979 92.727,130.094C85.15,108.985 70.726,104.547 59.957,104.547C45.408,104.547 30.026,112.303 19.082,119.336L38.472,63.651L270.628,63.651L272.972,66.621L273.598,66.142C273.647,66.109 273.701,66.066 273.729,66.028L302.991,119.092C289.097,126.945 245.324,150.431 218.852,150.431Z"
            style={{ fill: fill, fillRule: "nonzero" }}
          />
        </g>
      </g>
    </g>
  </svg>
);

export const Meso = ({ fill }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1835 1835"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
  >
    <g transform="matrix(1.33333,0,0,1.33333,-406.849,-408.244)">
      <g>
        <g transform="matrix(18.9416,0,0,18.9416,115.896,-2304.85)">
          <path
            d="M20.628,148.484C34.811,134.301 57.806,134.301 71.988,148.484C86.171,162.667 86.171,185.661 71.988,199.845C57.806,214.027 34.811,214.027 20.628,199.845C6.445,185.661 6.445,162.667 20.628,148.484Z"
            style={{
              fill: fill,
              fillOpacity: "0.38",
              fillRule: "nonzero",
            }}
          />
        </g>
        <g transform="matrix(4.63817,0,0,4.63817,556.166,572.291)">
          <g>
            <g>
              <path
                d="M191.192,43.137C191.085,38.23 189.589,33.197 186.471,28.746C180.997,20.912 172.501,16.642 162.78,17.212C150.622,17.968 135.22,23.165 120.325,28.187L112.92,30.667C106.799,32.703 100.948,32.993 93.54,33.362C83.922,33.841 71.966,34.43 55.111,39.303C29.69,46.667 12.145,59.384 4.391,76.067C1.508,82.253 0.151,88.919 0.105,95.745C0.087,95.763 0,95.843 0,95.843L0,116.513C-0.038,123.979 1.611,131.661 4.931,139.152C13.824,159.145 39.567,174.224 64.82,174.224C82.43,174.224 98.783,167.247 112.113,154.039C123.783,142.485 127.974,135.039 132.443,127.161C135.533,121.703 138.698,116.076 144.894,108.53C150.2,102.06 156.992,98.898 164.181,95.552C174.365,90.809 184.896,85.904 190.097,71.664C191.047,69.033 191.468,66.216 191.335,63.365L191.384,43.34C191.325,43.245 191.241,43.197 191.192,43.137ZM13.762,80.426C20.226,66.53 35.516,55.744 57.982,49.236C73.675,44.69 84.489,44.157 94.041,43.678C101.916,43.293 108.724,42.949 116.17,40.472L123.624,37.977C137.882,33.166 152.625,28.187 163.417,27.519L164.499,27.489C169.897,27.489 174.803,30.104 177.992,34.664C180.842,38.74 181.755,43.63 180.377,47.421C176.642,57.632 169.22,61.101 159.799,65.483C152.257,69.002 143.701,72.989 136.893,81.28C130.148,89.513 126.726,95.542 123.432,101.391C119.176,108.937 115.481,115.453 104.827,126.001C93.474,137.262 79.644,143.196 64.813,143.196C41.293,143.196 20.693,128.477 14.375,114.262C10.734,106.046 7.671,93.537 13.762,80.426Z"
                style={{ fill: fill, fillRule: "nonzero" }}
              />
              <path
                d="M64.821,132.848C77.037,132.848 88.05,128.078 97.555,118.641C107.173,109.127 110.375,103.475 114.415,96.322C117.772,90.373 121.567,83.651 128.905,74.712C137.205,64.599 147.327,59.888 155.446,56.105C165.241,51.535 168.591,49.581 170.663,43.873C170.755,43.474 170.53,42.014 169.52,40.585C168.799,39.549 167.176,37.814 164.507,37.814C154.632,38.419 140.554,43.168 126.957,47.76L119.443,50.279C110.642,53.2 102.848,53.587 94.581,54.002C85.56,54.449 75.387,54.96 60.864,59.16C41.349,64.81 28.301,73.664 23.127,84.772C18.677,94.36 21.049,103.817 23.828,110.056C28.7,121.021 45.761,132.848 64.821,132.848ZM63.905,75.477C77.374,75.477 88.294,83.139 88.294,92.609C88.294,102.061 77.374,109.736 63.905,109.736C50.424,109.736 39.502,102.06 39.502,92.609C39.502,83.139 50.424,75.477 63.905,75.477Z"
                style={{ fill: fill, fillRule: "nonzero" }}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export const Povrce = ({ fill }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1835 1835"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
  >
    <g transform="matrix(1.33333,0,0,1.33333,-406.849,-408.244)">
      <g>
        <g transform="matrix(18.9416,0,0,18.9416,115.896,-2304.85)">
          <path
            d="M20.628,148.484C34.811,134.301 57.806,134.301 71.988,148.484C86.171,162.667 86.171,185.661 71.988,199.845C57.806,214.027 34.811,214.027 20.628,199.845C6.445,185.661 6.445,162.667 20.628,148.484Z"
            style={{
              fill: fill,
              fillOpacity: "0.38",
              fillRule: "nonzero",
            }}
          />
        </g>
        <g transform="matrix(2.13817,0.863267,-0.863267,2.13817,710.295,342.935)">
          <path
            d="M126.392,110.916L192.205,99.588C192.185,94.051 191.21,88.262 191.027,83.309C190.214,61.211 191.619,34.723 203.935,15.608C206.13,12.178 208.517,9.045 206.439,4.69C203.736,-0.974 196.771,-1.316 192.523,2.635C184.7,10.338 179.426,19.953 174.822,29.805C169.611,40.951 166.201,52.778 163.815,64.842C161.266,48.172 160.957,30.52 166.834,15.43C168.295,11.629 170.014,8.086 167.104,4.231C163.327,-0.775 156.428,0.276 153.059,5.001C146.932,14.112 143.692,24.591 141.151,35.163C139.698,41.206 138.733,47.329 138.141,53.495C131.419,41.569 123.227,30.505 113.135,21.721C109.689,18.795 104.816,13.911 99.877,13.827C93.528,13.72 89.275,20.706 92.287,26.296C93.59,28.714 96.727,30.731 98.541,32.884C102.006,37.017 105.181,41.478 107.692,46.263C118.381,66.615 123.056,88.349 126.392,110.916Z"
            style={{ fill: fill, fillRule: "nonzero" }}
          />
          <path
            d="M189.667,370.707C188.327,321.414 193.188,256.959 198.334,188.719L198.538,186.022C201.116,151.833 206.584,129.594 216.338,116.805C210.32,110.444 203.49,109.418 196.673,109.418C189.051,109.418 180.358,111.117 170.291,113.084L149.217,116.789C130.597,119.703 115.891,122.006 109.045,132.607C102.684,142.459 102.514,160.05 108.633,187.33C122.848,191.754 137.766,194.117 152.535,195.1C162.515,195.764 172.875,195.289 182.708,193.518C185.312,193.049 192.217,190.487 193.041,195.096C193.889,199.813 184.415,200.647 181.634,201.407C161.751,205.438 142.393,206.05 122.213,203.913C118.922,203.427 115.64,202.915 112.37,202.354C112.558,203.049 112.743,203.741 112.937,204.446L113.653,207.044C141.652,308.64 170.458,413.153 200.962,442.665C199.604,439.575 198.37,436.163 197.261,432.411C192.877,417.568 190.392,397.385 189.667,370.707Z"
            style={{ fill: fill, fillRule: "nonzero" }}
          />
          <path
            d="M351.523,23.654C341.554,28.205 333.33,35.558 325.691,43.258C316.827,52.192 309.504,62.432 303.086,73.252C306.25,56.531 312.573,39.039 323.023,27.029C325.682,23.945 328.493,21.185 327.055,16.578C325.188,10.594 318.337,9.25 313.574,12.564C304.72,19.035 298.136,27.859 292.187,36.937C288.675,42.298 285.628,47.914 282.933,53.699C280.873,41.959 277.611,30.459 272.705,19.429C270.533,14.861 267.901,9.056 264.111,5.519C259.847,0.246 251.104,2.099 249.368,8.665C248.317,12.642 250.78,15.337 252.136,18.712C254.199,23.804 255.564,28.927 256.633,34.314C261.794,56.595 257.173,81.289 252.736,103.267L318.674,114.853C321.071,108.478 322.233,101.177 323.952,95.759C329.583,76.249 341.428,50.923 358.651,39.138C367.277,32.873 362.838,18.76 351.523,23.654Z"
            style={{ fill: fill, fillRule: "nonzero" }}
          />
          <path
            d="M293.324,120.123C289.918,119.603 286.394,119.066 282.77,118.455C279.121,117.818 275.622,117.148 272.238,116.502C262.25,114.592 253.623,112.941 246.048,112.941C231.706,112.941 217.328,117.431 210.67,165.927C227.004,179.081 245.161,190.101 263.33,196.122C269.195,198.065 277.048,200.067 284.047,201.069C289.817,201.893 289.715,207.784 283.484,207.807C277.254,207.83 252.941,206.268 219.414,188.469C216.32,186.826 212.3,184.953 208.834,182.752C208.721,184.07 208.612,185.404 208.509,186.772L208.306,189.469C198.885,314.395 189.144,443.573 224.653,454.313L225.178,454.442L225.487,454.458C225.735,454.468 225.986,454.474 226.234,454.474C250.406,454.48 273.245,400.808 295.313,329.914C286.219,329.801 277.136,329.178 268.182,327.455C262.067,326.279 256.1,324.812 250.153,322.967C243.377,320.852 245.369,315.076 251.834,316.028C265.049,317.971 278.1,317.811 291.364,316.423C293.764,316.073 296.878,315.171 299.985,314.626C304.854,298.407 309.687,281.468 314.491,264.208C309.979,263.673 305.493,262.95 301.07,261.938C295.37,260.635 289.817,259.055 284.264,257.217C277.939,255.123 280.473,249.082 287.459,250.778C297.474,253.21 307.754,252.305 317.972,251.626C321.754,237.892 325.518,224.019 329.267,210.196L329.962,207.633C340.071,170.36 341.216,147.528 333.565,135.779C326.675,125.205 311.958,122.963 293.324,120.123Z"
            style={{ fill: fill, fillRule: "nonzero" }}
          />
        </g>
      </g>
    </g>
  </svg>
);
export const Zitarice = ({ fill }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1835 1835"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
  >
    <g transform="matrix(1.33333,0,0,1.33333,-406.849,-408.244)">
      <g>
        <g transform="matrix(18.9416,0,0,18.9416,115.896,-2304.85)">
          <path
            d="M20.628,148.484C34.811,134.301 57.806,134.301 71.988,148.484C86.171,162.667 86.171,185.661 71.988,199.845C57.806,214.027 34.811,214.027 20.628,199.845C6.445,185.661 6.445,162.667 20.628,148.484Z"
            style={{
              fill: fill,
              fillOpacity: "0.38",
              fillRule: "nonzero",
            }}
          />
        </g>
        <g transform="matrix(0.941074,-0.338202,0.338202,0.941074,-265.057,377.998)">
          <g transform="matrix(36.2008,0,0,36.2008,455.752,474.517)">
            <path
              d="M21.184,2.555C21.872,3.757 21.309,4.217 20.748,4.532C20.187,4.846 19.513,4.957 18.827,3.758C18.136,2.556 18.11,0 18.11,0C18.11,0 20.494,1.354 21.184,2.555Z"
              style={{ fill: fill, fillRule: "nonzero" }}
            />
          </g>
          <g transform="matrix(36.2008,0,0,36.2008,455.752,474.517)">
            <path
              d="M24.16,6.004C23.565,7.255 22.868,7.057 22.29,6.779C21.709,6.496 21.234,6.006 21.823,4.759C22.418,3.505 24.497,2.023 24.497,2.023C24.497,2.023 24.751,4.754 24.16,6.004Z"
              style={{ fill: fill, fillRule: "nonzero" }}
            />
          </g>
          <g transform="matrix(36.2008,0,0,36.2008,455.752,474.517)">
            <path
              d="M24.564,10.728C23.667,11.784 23.043,11.413 22.558,10.995C22.071,10.574 21.738,9.977 22.628,8.924C23.523,7.864 25.919,6.97 25.919,6.97C25.919,6.97 25.458,9.672 24.564,10.728Z"
              style={{ fill: fill, fillRule: "nonzero" }}
            />
          </g>
          <g transform="matrix(36.2008,0,0,36.2008,455.752,474.517)">
            <path
              d="M20.565,9.336C21.794,10.052 21.543,10.732 21.215,11.285C20.886,11.839 20.352,12.269 19.125,11.556C17.893,10.841 16.535,8.61 16.535,8.61C16.535,8.61 19.333,8.622 20.565,9.336Z"
              style={{ fill: fill, fillRule: "nonzero" }}
            />
          </g>
          <g transform="matrix(36.2008,0,0,36.2008,455.752,474.517)">
            <path
              d="M22.944,15.583C21.742,16.271 21.282,15.711 20.965,15.152C20.65,14.591 20.539,13.915 21.736,13.227C22.937,12.536 25.495,12.506 25.495,12.506C25.495,12.506 24.145,14.893 22.944,15.583Z"
              style={{ fill: fill, fillRule: "nonzero" }}
            />
          </g>
          <g transform="matrix(36.2008,0,0,36.2008,455.752,474.517)">
            <path
              d="M19.835,13.057C20.715,14.178 20.228,14.716 19.721,15.109C19.212,15.505 18.552,15.705 17.676,14.588C16.798,13.467 16.364,10.893 16.364,10.893C16.364,10.893 18.956,11.94 19.835,13.057Z"
              style={{ fill: fill, fillRule: "nonzero" }}
            />
          </g>
          <g transform="matrix(36.2008,0,0,36.2008,455.752,474.517)">
            <path
              d="M20.428,19.678C19.062,19.918 18.821,19.235 18.712,18.602C18.604,17.966 18.727,17.295 20.084,17.052C21.451,16.809 23.867,17.649 23.867,17.649C23.867,17.649 21.786,19.434 20.428,19.678Z"
              style={{ fill: fill, fillRule: "nonzero" }}
            />
          </g>
          <g transform="matrix(36.2008,0,0,36.2008,455.752,474.517)">
            <path
              d="M18.198,16.285C18.799,17.577 18.198,17.989 17.614,18.256C17.027,18.522 16.343,18.567 15.748,17.281C15.147,15.988 15.316,13.381 15.316,13.381C15.316,13.381 17.602,14.995 18.198,16.285Z"
              style={{ fill: fill, fillRule: "nonzero" }}
            />
          </g>
          <g transform="matrix(36.2008,0,0,36.2008,455.752,474.517)">
            <path
              d="M15.474,18.787C15.719,20.187 15.036,20.432 14.404,20.54C13.768,20.643 13.092,20.512 12.849,19.116C12.604,17.712 13.436,15.236 13.436,15.236C13.436,15.236 15.228,17.385 15.474,18.787Z"
              style={{ fill: fill, fillRule: "nonzero" }}
            />
          </g>
          <g transform="matrix(36.2008,0,0,36.2008,455.752,474.517)">
            <path
              d="M16.792,22.827C15.383,22.618 15.368,21.89 15.465,21.257C15.569,20.619 15.905,20.023 17.308,20.233C18.717,20.444 20.804,22.016 20.804,22.016C20.804,22.016 18.197,23.037 16.792,22.827Z"
              style={{ fill: fill, fillRule: "nonzero" }}
            />
          </g>
          <g transform="matrix(36.2008,0,0,36.2008,455.752,474.517)">
            <path
              d="M12.398,21.047C12.5,22.467 11.795,22.641 11.154,22.684C10.511,22.721 9.854,22.52 9.751,21.108C9.653,19.686 10.734,17.309 10.734,17.309C10.734,17.309 12.299,19.63 12.398,21.047Z"
              style={{ fill: fill, fillRule: "nonzero" }}
            />
          </g>
          <g transform="matrix(36.2008,0,0,36.2008,455.752,474.517)">
            <path
              d="M12.762,25.197C11.418,24.72 11.541,24.005 11.761,23.401C11.982,22.795 12.429,22.274 13.766,22.747C15.108,23.224 16.854,25.166 16.854,25.166C16.854,25.166 14.1,25.67 12.762,25.197Z"
              style={{ fill: fill, fillRule: "nonzero" }}
            />
          </g>
          <g transform="matrix(36.2008,0,0,36.2008,455.752,474.517)">
            <path
              d="M20.163,5.464C21.529,5.834 21.461,6.56 21.289,7.176C21.115,7.797 20.71,8.351 19.35,7.982C17.979,7.612 16.107,5.812 16.107,5.812C16.107,5.812 18.798,5.095 20.163,5.464Z"
              style={{ fill: fill, fillRule: "nonzero" }}
            />
          </g>
          <g transform="matrix(36.2008,0,0,36.2008,455.752,474.517)">
            <path
              d="M10.638,23.44C10.638,23.44 4.185,25.978 0.159,25.074C-0.157,25.45 0.605,26 0.605,26C0.605,26 6.465,26.091 10.638,23.44Z"
              style={{ fill: fill, fillRule: "nonzero" }}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export const Vino = ({ fill }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1835 1835"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
  >
    <g transform="matrix(1.33333,0,0,1.33333,-416.125,-416.122)">
      <g>
        <g transform="matrix(18.9416,0,0,18.9416,122.853,-2298.95)">
          <path
            d="M20.628,148.484C34.811,134.301 57.806,134.301 71.988,148.484C86.171,162.667 86.171,185.661 71.988,199.845C57.806,214.027 34.811,214.027 20.628,199.845C6.445,185.661 6.445,162.667 20.628,148.484Z"
            style={{
              fill: fill,
              fillOpacity: "0.38",
              fillRule: "nonzero",
            }}
          />
        </g>
        <g
          id="oversized-wine-glass-2"
          transform="matrix(1.92012,0,0,1.92012,524.5,560.146)"
        >
          <path
            d="M264.082,433.09L264.082,263.641C343.832,254.508 400.573,187.733 400.573,111.561C400.573,82.766 392.577,46.951 378.719,12.231C375.775,4.847 368.617,0 360.671,0L134.481,0C126.53,0 119.373,4.847 116.432,12.232C103.92,43.581 94.199,80.534 94.728,114.633C94.728,114.665 94.737,114.697 94.737,114.728C96.277,189.853 152.551,254.871 231.06,263.641L231.06,433.09C179.081,437.344 139.237,460.448 139.237,472.064C139.237,484.898 187.738,495.281 247.578,495.281C307.417,495.281 355.923,484.898 355.923,472.064C355.923,460.448 316.066,437.339 264.082,433.09ZM143.823,33.021L351.325,33.021C361.686,61.276 367.552,89.491 367.552,111.562C367.552,116.921 367.076,122.172 366.392,127.354C356.068,124.781 345.367,124.441 333.888,128.527C303.911,139.2 284.477,146.036 253.979,134.076C223.654,122.175 204.491,68.116 168.127,72.229C153.68,73.855 141.285,78.607 130.096,85.208C132.692,68.828 137.222,51.028 143.823,33.021Z"
            style={{ fill: fill, fillRule: "nonzero" }}
          />
        </g>
      </g>
    </g>
  </svg>
);
export const Dostava = ({ fill }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1835 1835"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
    >
      <g transform="matrix(1.33333,0,0,1.33333,-328.287,-443.085)">
        <g>
          <g transform="matrix(18.9416,0,0,18.9416,56.9749,-2278.72)">
            <path
              d="M20.628,148.484C34.811,134.301 57.806,134.301 71.988,148.484C86.171,162.667 86.171,185.661 71.988,199.845C57.806,214.027 34.811,214.027 20.628,199.845C6.445,185.661 6.445,162.667 20.628,148.484Z"
              style={{
                fill: fill,
                fillOpacity: "0.38",
                fillRule: "nonzero",
              }}
            />
          </g>
          <g transform="matrix(1.59183,0,0,1.59183,490.615,612.715)">
            <path
              d="M624,352L608,352L608,243.9C608,231.2 602.9,219 593.9,210L494,110.1C485,101.1 472.8,96 460.1,96L416,96L416,48C416,21.5 394.5,0 368,0L48,0C21.5,0 0,21.5 0,48L0,368C0,394.5 21.5,416 48,416L64,416C64,469 107,512 160,512C213,512 256,469 256,416L384,416C384,469 427,512 480,512C533,512 576,469 576,416L624,416C632.8,416 640,408.8 640,400L640,368C640,359.2 632.8,352 624,352ZM160,464C133.5,464 112,442.5 112,416C112,389.5 133.5,368 160,368C186.5,368 208,389.5 208,416C208,442.5 186.5,464 160,464ZM480,464C453.5,464 432,442.5 432,416C432,389.5 453.5,368 480,368C506.5,368 528,389.5 528,416C528,442.5 506.5,464 480,464ZM560,256L416,256L416,144L460.1,144L560,243.9L560,256Z"
              style={{ fill: fill, fillRule: "nonzero" }}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
