import React from "react";
import { Link, useParams } from "react-router-dom";
/* import styled from "styled-components";
import { getIcon } from "../Utils/SVG"; */
import { handlePostRequest } from "../Utils/handle-requests";

import { categoryColors, categoryImages } from "../../variables";

import background from "../../assets/img/106783.jpg";

const About = () => {
  let { id } = useParams();
  const [vendor, setVendor] = React.useState({ loading: true });
  const [produce, setProduce] = React.useState({ loading: true });

  React.useEffect(() => {
    handlePostRequest(`${process.env.REACT_APP_URL}ProdavacGetById`, [
      {
        reqID: Number(id),
      },
    ])
      .then((res) => {
        setVendor(JSON.parse(res.data)[0]);
        if (res.data.length > 0) {
          handlePostRequest(
            `${process.env.REACT_APP_URL}ProizvodGetByProizvodac`,
            [
              {
                proizvodacID: Number(id),
              },
            ]
          )
            .then((res) => {
              setProduce(JSON.parse(res.data));
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  }, [id]);
  /*   React.useEffect(() => {}, [id]); */
  return (
    <>
      <div
        className="relative pt-24 pb-32 flex content-center items-center justify-center"
        style={{ minHeight: "35vh" }}
      >
        <div
          className="absolute top-0 w-full h-full bg-center bg-cover"
          style={{
            backgroundImage: `url(${background})`,
          }}
        >
          <span
            id="blackOverlay"
            className="w-full h-full absolute opacity-25 bg-black"
          ></span>
        </div>
        {/*  <div className="container relative mx-auto">
          <div className="flex flex-wrap">
            <div className="w-full px-4 ml-auto mr-auto text-center ">
              <div className=" sm:pr-12">
                <h1 className="text-white font-semibold text-3xl sm:text-5xl md:text-6xl">
                  {vendor.NazivOPG}
                </h1>
              </div>
            </div>
          </div>
        </div> */}
        <div
          className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
          style={{ height: "70px", transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-gray-200 fill-current"
              points="2700 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>
      <section className="bg-gray-200 pb-20 pt-16">
        <section className="relative py-16 bg-gray-200">
          <div className="container mx-auto px-4 mt-16">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative">
                      <img
                        alt="..."
                        src={require("../../assets/img/pp_logo.png")}
                        className=" h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16"
                        style={{ maxWidth: "150px" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-24">
                  <h3 className="text-2xl md:text-4xl text-center font-semibold leading-normal mb-2 text-gray-800 mb-2">
                    {vendor.NazivOPG}
                  </h3>
                  <div className="container mt-8 mx-auto flex flex-wrap">
                    {!vendor.loading ? (
                      <div className="lg:px-12 lg:px-2 w-full">
                        <div className="flex -mx-2 flex-wrap">
                          <div className="w-full md:w-3/5 px-2">
                            <div className="flex -mx-2 mb-2">
                              <div className="w-2/12 sm:w-1/12 px-2 text-center self-center">
                                <i className="fas fa-shopping-basket  text-2xl sm:2ext-4xl "></i>
                              </div>
                              <div className="w-10/12 px-4">
                                <div className="">
                                  <h2 className="text-xl sm:text-3xl">
                                    Proizvodi
                                  </h2>
                                </div>
                              </div>
                            </div>

                            {!produce.loading ? (
                              produce.map((item) => {
                                return (
                                  <React.Fragment key={item.ID}>
                                    <div className="flex -mx-2 mb-2 lg:mb-4 ">
                                      <div className="w-1/12 sm:w-1/12 px-2 text-center"></div>
                                      <div className="w-9/12 sm:w-8/12 lg:w-4/12 md:px-4">
                                        <div>
                                          <p className="text-sm sm:text-l lg:text-xl ">
                                            {item.Naziv}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="w-9/12 sm:w-8/12 lg:w-7/12 px-4">
                                        <div>
                                          <p className="text-sm sm:text-l lg:text-xl lg:ml-2">
                                            {item.Cijena} HRK / {item.Mjera}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                );
                              })
                            ) : (
                              <>
                                <div className="flex -mx-2 mb-6 lg:mb-4">
                                  <div className="w-1/12 sm:w-1/12 px-2 text-center"></div>
                                  <div className="w-9/12 sm:w-8/12 lg:w-4/12 px-4">
                                    <div className="skeleton-box bg-gray-400 h-8"></div>
                                  </div>
                                  <div className="w-9/12 sm:w-8/12 lg:w-4/12 px-4">
                                    <div className="skeleton-box bg-gray-600 h-8"></div>
                                  </div>
                                </div>
                                <div className="flex -mx-2 mb-6 lg:mb-4">
                                  <div className="w-1/12 sm:w-1/12 px-2 text-center"></div>
                                  <div className="w-9/12 sm:w-8/12 lg:w-4/12 px-4">
                                    <div className="skeleton-box bg-gray-400 h-8"></div>
                                  </div>
                                  <div className="w-9/12 sm:w-8/12 lg:w-4/12 px-4">
                                    <div className="skeleton-box bg-gray-600 h-8"></div>
                                  </div>
                                </div>
                              </>
                            )}

                            {vendor.hasOwnProperty("Dostava") &&
                              vendor.Dostava.length !== 0 && (
                                <>
                                  <div className="flex -mx-2 mb-2 mt-6">
                                    <div className="w-2/12 sm:w-1/12 px-2 text-center self-center">
                                      <i className="fas fa-truck  text-2xl sm:2ext-4xl "></i>
                                    </div>
                                    <div className="w-10/12 px-4">
                                      <div className="">
                                        <h2 className="text-xl sm:text-2xl">
                                          Dostava
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex -mx-2 mb-2 lg:mb-4 ">
                                    <div className="w-1/12 sm:w-1/12 px-2 text-center"></div>
                                    <div className="w-9/12 sm:w-8/12 lg:w-4/12 md:px-4">
                                      <div>
                                        <p className="text-sm sm:text-l lg:text-xl ">
                                          {vendor.Dostava}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                          </div>
                          <div className="w-full md:w-2/5 px-2 mt-6 md:mt-0">
                            <div className="flex -mx-2">
                              <div className="w-2/12 sm:w-1/12 px-2 text-center self-center">
                                <i className="fas fa-user  text-2xl sm:text-2xl "></i>
                              </div>
                              <div className="w-10/12 px-4">
                                <div className="">
                                  <h2 className=" text-base md:text-l sm:text-xl">
                                    Kontakt osoba
                                  </h2>
                                </div>
                              </div>
                            </div>

                            <div className="flex -mx-2 mb-8">
                              <div className="w-2/12 sm:w-1/12 px-2 text-center"></div>
                              <div className="w-10/12 px-4">
                                <div>
                                  <p className="text-md sm:text-md ml-2">
                                    {vendor.Kontakt}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* First Row ending */}
                            <div className="flex -mx-2">
                              <div className="w-2/12 sm:w-1/12 px-2 text-center self-center">
                                <i className="fas fa-phone text-2xl sm:text-2xl "></i>
                              </div>
                              <div className="w-10/12 px-4">
                                <div>
                                  <h2 className=" text-base md:text-l sm:text-xl">
                                    Mobitel / Telefon
                                  </h2>
                                </div>
                              </div>
                            </div>

                            <div className="flex -mx-2 mb-8">
                              <div className="w-2/12 sm:w-1/12 px-2 text-center"></div>
                              <div className="w-10/12 px-4">
                                <div>
                                  <a
                                    href={`tel:${vendor.Mobitel}`}
                                    className="text-md sm:text-md ml-2"
                                  >
                                    {vendor.Mobitel}
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* Second row ending */}
                            <div className="flex -mx-2">
                              <div className="w-2/12 sm:w-1/12 px-2 lg:text-center self-center">
                                <i className="fas fa-home text-2xl sm:text-2xl "></i>
                              </div>
                              <div className="w-10/12 px-4">
                                <div>
                                  <h2 className=" text-base md:text-l sm:text-xl">
                                    Adresa
                                  </h2>
                                </div>
                              </div>
                            </div>

                            <div className="flex -mx-2 mb-8">
                              <div className="w-1/12 sm:w-1/12 px-2 lg:text-center"></div>
                              <div className="w-11/12 px-4">
                                <div className="ml-8 sm:ml-2">
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`https://www.google.com/maps/search/${vendor.Adresa.replace(
                                      " ",
                                      "+"
                                    )}+${vendor.Grad}+${vendor.PostanskiBroj}`}
                                    className="text-md sm:text-l "
                                  >
                                    {vendor.Adresa}, {vendor.Grad}{" "}
                                    {vendor.PostanskiBroj}
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* Third row ending */}
                            <div className="flex -mx-2 mb-2 ">
                              <div className="px-2 lg:px-4 w-full">
                                <div className="flex -mx-2 flex-wrap">
                                  {vendor.Email.length > 0 && (
                                    <div className="w-full xl:w-1/3 px-2 xl:text-center">
                                      <div className="flex -mx-2">
                                        <div className="w-2/12 sm:w-1/12 px-2 xl:text-center self-center">
                                          <i className="fas fa-envelope  text-2xl sm:text-2xl "></i>
                                        </div>
                                        <div className="w-10/12 ml-4 lg:ml-6 xl:ml-0">
                                          <div className="">
                                            <h2 className=" text-base md:text-l sm:text-xl">
                                              Email
                                            </h2>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="flex -mx-2 mb-8">
                                        <div className="w-2/12 sm:w-1/12 px-2 xl:text-center"></div>
                                        <div className="w-10/12 px-4 lg:px-0">
                                          <div>
                                            <a
                                              href={`mailto:${vendor.Email}`}
                                              className="text-sm sm:text-md"
                                            >
                                              {vendor.Email}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {vendor.Facebook.length > 0 && (
                                    <div className="w-full xl:w-1/3 px-2 xl:text-center">
                                      <div className="flex -mx-2">
                                        <div className="w-2/12 sm:w-1/12 px-2 xl:text-center self-center">
                                          <i className="fab fa-facebook-square  text-2xl sm:2ext-4xl "></i>
                                        </div>
                                        <div className="w-10/12 ml-4 lg:ml-6 xl:ml-6">
                                          <div className="">
                                            <h2 className=" text-base md:text-l sm:text-xl">
                                              Facebook
                                            </h2>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="flex -mx-2 mb-8">
                                        <div className="w-2/12 sm:w-1/12 px-2 text-center"></div>
                                        <div className="w-10/12 px-4">
                                          <div>
                                            <a
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              href={vendor.Facebook}
                                              className="text-md ml-2 sm:text-md"
                                            >
                                              Link
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {vendor.Webstranica.length > 0 && (
                                    <div className="w-full xl:w-1/3 px-2 xl:text-center">
                                      <div className="flex -mx-2">
                                        <div className="w-2/12 sm:w-1/12 px-2 xl:text-center self-center">
                                          <i className="fas fa-external-link-alt  text-2xl sm:text-2xl"></i>
                                        </div>
                                        <div className="w-8/12 ml-4 lg:ml-6 xl:ml-2">
                                          <div className="">
                                            <h2 className=" text-base md:text-l sm:text-xl">
                                              Web
                                            </h2>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="flex -mx-2 mb-8">
                                        <div className="w-2/12 sm:w-1/12 px-2 text-center"></div>
                                        <div className="w-8/12 px-4">
                                          <div>
                                            <a
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              href={vendor.Webstranica}
                                              className="text-md ml-2 sm:text-md "
                                            >
                                              Link
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Grid starts here */}

                        {/*Produce list ends here  */}

                        {/* Second row ending */}

                        {/* Grid ends here */}
                      </div>
                    ) : (
                      <div className="flex justify-between flex-wrap w-full">
                        <div className="loader ease-in mx-auto  rounded-full border-2 border-t-2 border-gray-200 h-8 w-8 sm:w-12 sm:h-12 md:h-20 md:w-20 my-6 md:my-0 md:mt-6"></div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-10 py-5 border-t border-gray-300 text-center">
                  {vendor.hasOwnProperty("Napomena") &&
                    vendor.Napomena.length !== 0 && (
                      <div className="flex flex-wrap justify-center">
                        <div className="w-full lg:w-9/12 px-4">
                          <h1 className="text-center text-2xl">Napomena</h1>
                          <p className="mb-4 text-lg leading-relaxed text-gray-800">
                            {vendor.Napomena}
                          </p>
                          {/*  <a
                        href="#pablo"
                        className="font-normal text-pink-500"
                        onClick={(e) => e.preventDefault()}
                      >
                        Show more
                      </a> */}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-center mt-16">
            <div className="container flex mx-auto flex-wrap">
              {categoryImages.map((item) => {
                return (
                  <div
                    key={item.name}
                    className="w-full md:w-4/12 px-4 mr-auto ml-auto"
                  >
                    <Link to={`/kategorija/${item.name}`}>
                      <div
                        className={`relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-${
                          categoryColors[item.name]
                        }`}
                      >
                        <img
                          alt="..."
                          src={require(`../../assets/img/${item.image}`)}
                          className="w-full align-middle rounded-t-lg h-10 object-cover"
                        />
                        <blockquote className="relative p-1 mb-1  ">
                          <svg
                            preserveAspectRatio="none"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 583 95"
                            className="absolute left-0 w-full block"
                            style={{
                              height: "65px",
                              top: "-64px",
                            }}
                          >
                            <polygon
                              points="-30,95 583,95 583,65"
                              className={`text-${
                                categoryColors[item.name]
                              } fill-current`}
                            ></polygon>
                          </svg>
                          <h4 className="text-l font-bold text-white ml-8">
                            {item.name}
                          </h4>
                        </blockquote>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default About;
