import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const images = {
  "Cvijeće, sadnice i flanci": "cvijece.jpg",
};

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const svgColors = {
  Med: "#F2CDA0",
  "Jaja i tjestenine": "#F2CDA0",
  "Krumpir i žitarice": "#F2CDA0",
  Povrće: "#A3D9C9",
  "Sirevi, vrhnje i mlijeko": "#9ACDD9",
  "Cvijeće, sadnice i flanci": "#F2BBD9",
  "Voće i voćni pripravci": "#F2BBD9",
  "Vina, rakije i čajevi": "#F2B6C1",
  Meso: "#F2B6C1",
};
export const categoryColors = {
  Med: "yellow-600",
  "Jaja i tjestenine": "yellow-500",
  "Krumpir i žitarice": "orange-600",
  Povrće: "green-600",
  "Sirevi, vrhnje i mlijeko": "blue-500",
  "Cvijeće, sadnice i flanci": "pink-600",
  "Voće i voćni pripravci": "pink-500",
  "Vina, rakije i čajevi": "red-600",
  Meso: "red-500",
};
export const categoryImages = [
  { name: "Cvijeće, sadnice i flanci", image: "cvijece.jpg" },
  { name: "Jaja i tjestenine", image: "jaja.jpg" },
  { name: "Krumpir i žitarice", image: "krumpir.jpg" },
  { name: "Med", image: "med.jpg" },
  { name: "Povrće", image: "povrce.jpg" },
  { name: "Sirevi, vrhnje i mlijeko", image: "mlijeko.jpg" },
  { name: "Vina, rakije i čajevi", image: "vino.jpg" },
  { name: "Voće i voćni pripravci", image: "voce.jpg" },
  { name: "Meso", image: "meso.jpg" },
];
