import React from "react";
import { Router, Switch, Route, Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { createBrowserHistory } from "history";
import Footer from "../Layout/Footer";
import Home from "../Home/Home";
import Categories from "../Categories/Categories";
import Tag from "../Tag/Tag";
import About from "../About/About";
import { ScrollToTop } from "../../variables";

import { handlePostRequest } from "../Utils/handle-requests";

const history = createBrowserHistory();

function App() {
  const [categories, setCategories] = React.useState([]);

  const getCategories = React.useCallback(() => {
    const fetchData = async () => {
      const result =  await handlePostRequest(
        `${process.env.REACT_APP_URL}KategorijaGets`,
        []
      );
      setCategories(JSON.parse(result.data));
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <div className="text-gray-800 antialiased">
      <Router history={history}>
        <ScrollToTop />
        <nav className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
          <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
            <div className="relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
              <Link to="/">
                <img
                  src={require("../../assets/img/pp_logo.png")}
                  alt="logo"
                  className=" rounded w-16 h-auto align-middle border-none"
                />
              </Link>
            </div>
            <div className={"flex items-center"} id="example-navbar-danger">
              <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                <li className="nav-item">
                  <HashLink
                    className="bg-red-500 text-white active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
                    to="/#form"
                    style={{ transition: "all 0.15s ease 0s" }}
                  >
                    Oglasi svoj OPG
                  </HashLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <Switch>
          <Route exact path="/">
            <Home categories={categories} history={history}></Home>
          </Route>
          <Route path="/kategorija/:type">
            <Categories history={history}></Categories>
          </Route>
          <Route path="/trazi/:tag">
            <Tag history={history}></Tag>
          </Route>
          <Route path="/prodavac/:id">
            <About></About>
          </Route>
        </Switch>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
