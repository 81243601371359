import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import NewPost from "../NewPost/NewPost";
import { categoryColors /* categoryImages */ } from "../../variables";
/* import { handlePostRequest } from "../Utils/handle-requests"; */

import background from "../../assets/img/106783.jpg";

const Home = ({ history, categories }) => {
  const { register, handleSubmit } = useForm();
  const search = React.useRef(null);
  const submit = (data) => {
    history.push(`/trazi/${data.Search}`);
  };
  return (
    <>
      <main>
        <div
          className="relative pt-24 pb-32 flex content-center items-center justify-center"
          style={{ minHeight: "75vh" }}
        >
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{ backgroundImage: `url(${background})` }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-25 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="flex flex-wrap">
              <div className="w-full px-4 ml-auto mr-auto text-center ">
                <div className="flex flex-wrap justify-center">
                  <div className="w-6/12 sm:w-4/12 px-4">
                    <img
                      src={require("../../assets/img/pp_logo2.png")}
                      alt="..."
                      className="none sm:block rounded max-w-full h-auto align-middle border-none"
                    />
                  </div>
                </div>
                <div className="px-4 lg:px-0 lg:pr-12">
                  {/*  <h1 className="text-white font-semibold text-6xl">
                  Pitomački pijac
                  <span
                    className="text-4xl"
                    style={{ fontFamily: "'Muli', sans-serif" }}
                  >
                    online
                  </span>
                </h1> */}
                  <form className="w-full " onSubmit={handleSubmit(submit)}>
                    <div
                      ref={search}
                      className="flex bg-black-clear items-center border-b border-b-2 border-teal-500 py-2 mt-4  mb-8 sm:mb-0"
                    >
                      <input
                        className="bg-transparent border-teal-700 text-white w-full py-1 px-2 leading-tight focus:outline-none placeholder-white text-xl"
                        type="text"
                        name="Search"
                        placeholder="Što želiš kupiti?"
                        ref={register({ required: true, maxLength: 80 })}
                      />

                      <button
                        className="hidden sm:inline-block bg-green-500 text-white active:bg-green-600 font-bold uppercase text-base px-8 py-3 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-4 mb-1"
                        style={{ transition: "all .15s ease" }}
                      >
                        Pronađi
                      </button>
                    </div>
                    <div>
                      <button
                        className="sm:hidden mt-5 bg-green-500 text-white active:bg-green-600 font-bold uppercase text-base px-8 py-3 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        style={{ transition: "all .15s ease" }}
                      >
                        Pronađi
                      </button>
                    </div>
                  </form>
                </div>
                <div className="hidden sm:block px-6 text-center mt-12">
                  <br />
                  <div className="relative pt-1">
                    <div className="flex mb-2 justify-between">
                      <div>
                        <span className="text-5xl font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-transparent">
                          1.
                        </span>
                      </div>
                      <div>
                        <span className="text-5xl  font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-transparent">
                          2.
                        </span>
                      </div>
                      <div>
                        <span className="text-5xl flex-start font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-transparent">
                          3.
                        </span>
                      </div>
                    </div>
                    <div className="overflow-hidden h-12 mb-4 text-xl flex rounded">
                      <div className="w-1/3 shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-400">
                        <p
                          className="text-tiny md:text-sm lg:text-base"
                          onClick={() => search.current.childNodes[0].focus()}
                        >
                          <i className="fas fa-search"></i> Pronađi proizvod
                        </p>
                      </div>
                      <div className="w-1/3 shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500">
                        <p className="text-tiny md:text-sm lg:text-base">
                          <i className="fas fa-phone"></i> Kontaktiraj OPG
                          proizvođača
                        </p>
                      </div>
                      <div className="w-1/3 shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-600">
                        <p className="text-tiny md:text-sm lg:text-base">
                          <i className="fas fa-truck"></i> Preuzmi narudžbu na
                          kućnom pragu!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sm:hidden px-2 mt-12">
                  <div className="flex -mx-2 flex-wrap">
                    <div className="w-full px-2 flex">
                      <span className="w-2/12 text-3xl flex-start font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-transparent">
                        1.
                      </span>
                      <div className="w-9/12 shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-400 rounded-t">
                        <p className="text-xs md:text-sm lg:text-base">
                          <i className="fas fa-search"></i> Pronađi proizvod
                        </p>
                      </div>
                    </div>
                    <div className="w-full px-2 flex">
                      <span className="w-2/12 text-3xl flex-start font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-transparent">
                        2.
                      </span>
                      <div className="w-9/12 shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500 ">
                        <p className="text-xs md:text-sm lg:text-base">
                          <i className="fas fa-phone"></i> Kontaktiraj OPG
                          proizvođača
                        </p>
                      </div>
                    </div>
                    <div className="w-full px-2 flex">
                      <span className="w-2/12 text-3xl flex-start font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-transparent">
                        3.
                      </span>
                      <div className="w-9/12 shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-600 rounded-b">
                        <p className="text-xs md:text-sm lg:text-base">
                          <i className="fas fa-truck"></i> Preuzmi narudžbu na
                          kućnom pragu!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none"
            style={{ height: "70px", transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>
        <section className="pb-20 bg-gray-200 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Besplatna dostava</h6>
                    <p className="mt-2 mb-4 text-gray-600 text-xl">
                      Bez potrebe da izlaziš iz kuće, proizvođač će potpuno
                      besplatno dostaviti narudžbu na tvoj prag.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Bez posrednika</h6>
                    <p className="mt-2 mb-4 text-gray-600 text-xl">
                      Poveži se direktno s lokalnim OPG-ima - bez posrednika,
                      trgovačkih lanaca i bilo kakvih dodatnih troškova.
                    </p>
                  </div>
                </div>
              </div>
              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                      <i className="fas fa-fingerprint"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Uvijek svježe</h6>
                    <p className="mt-2 mb-4 text-gray-600 text-xl">
                      Kupujući lokalno, ne samo da podupireš lokalnu ekonomiju,
                      već i osiguravaš najkraći put proizvoda od polja do stola.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="container mt-12">
              <div className="text-center flex items-center border-b border-b-2 border-teal-500 py-2">
                <h1 className="text-green-600 font-semibold text-5xl">
                  Kategorije
                </h1>
              </div>
            </div>
            <div className="flex flex-wrap items-center mt-32">
              <div className="container flex flex-wrap">
                {categories.length !== 0
                  ? categories.map((item) => {
                      return (
                        <div
                          key={item.Naziv}
                          className="w-full md:w-4/12 px-4 mr-auto ml-auto"
                        >
                          <Link to={`/kategorija/${item.Naziv}`}>
                            <div
                              className={`relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-${
                                categoryColors[item.Naziv]
                              }`}
                            >
                              <img
                                alt="..."
                                src={require(`../../assets/img/${item.SlikaRef}`)}
                                className="w-full align-middle rounded-t-lg"
                              />
                              <blockquote className="relative p-8 mb-4">
                                <svg
                                  preserveAspectRatio="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 583 95"
                                  className="absolute left-0 w-full block"
                                  style={{
                                    height: "95px",
                                    top: "-94px",
                                  }}
                                >
                                  <polygon
                                    points="-30,95 583,95 583,65"
                                    className={`text-${
                                      categoryColors[item.Naziv]
                                    } fill-current`}
                                  ></polygon>
                                </svg>
                                <h4 className="text-3xl md:text-xl lg:text-3xl font-bold text-white">
                                  {item.Naziv}
                                </h4>
                              </blockquote>
                            </div>
                          </Link>
                        </div>
                      );
                    })
                  : [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => {
                      return (
                        <div
                          key={item}
                          className="w-full md:w-4/12 px-4 mr-auto ml-auto"
                        >
                          <div
                            className={` skeleton-box relative flex flex-col min-w-0 break-words bg-gray-400 w-full mb-6 shadow-lg rounded-lg`}
                          >
                            <div className="bg-gray-100 rounded-t h-64"></div>
                            <blockquote className="relative p-8 mb-4 h-20 bg-gray-400">
                              <svg
                                preserveAspectRatio="none"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 583 95"
                                className="absolute left-0 w-full block"
                                style={{
                                  height: "95px",
                                  top: "-94px",
                                  fill: "#CBD5E0",
                                }}
                              >
                                <polygon
                                  points="-30,95 583,95 583,65"
                                  className="text-gray-300 skeleton-box "
                                ></polygon>
                              </svg>
                            </blockquote>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </section>
        <section className="relative py-20 pb-32">
          <div className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none h-80 translate-z-0">
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src={require("../../assets/img/lonac.jpg")}
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4 mt-5 md:mt-0">
                <div className="md:pr-12">
                  <div className="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-pink-300">
                    <i className="fas fa-rocket text-xl"></i>
                  </div>
                  <h3 className="text-3xl font-semibold">O projektu</h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    Pitomački pijac neprofitni je projekt namijenjen lokalnim
                    OPG-ima i njihovim kupcima, kako bi se jednostavno i
                    efikasno povezali. U vremenu socijalne distance, online
                    prodaja važnija je no ikad, a Pitomački pijac želi stvoriti
                    jedinstvenu bazu proizvođača i njihovih proizvoda na
                    području VPŽ.
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="fas fa-award"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">
                            besplatno za OPG-ove
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="fas fa-award"></i>
                          </span>
                        </div>
                        <h4 className="text-gray-600">besplatno za kupce</h4>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-20 relative block bg-gray-900">
          <div className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none  h-80 translate-z-0">
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">
                  Pridružite se Pitomačkom pijacu
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-500">
                  U obrazac unesite podatke o proizvodima koje nudite, kao i
                  svoje kontakt podatke kako bi vas kupci mogli direktno
                  kontaktirati. Plaćanje se vrši direktno između prodavatelja i
                  kupca. Usluga je potpuno besplatna.
                </p>
                <p className="text-sm text-white">
                  Pitomački pijac i njegovi autori ne snose nikakvu financijsku,
                  poreznu niti drugu odgovornost za transakcije koje mogu
                  proizaći iz oglašavanja na ovoj web stranici.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-medal text-xl"></i>
                </div>
                <h6 className="text-xl mt-5 font-semibold text-white">
                  Unesite sve podatke
                </h6>
                <p className="mt-2 mb-4 text-gray-500">
                  Ispunite podatke o svom OPG-u i proizvodima koje nudite.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-poll text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  Oglas je spreman!
                </h5>
                <p className="mt-2 mb-4 text-gray-500">
                  Čim pošaljete podatke, oni će se pojaviti u odgovarajućoj
                  kategoriji.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-lightbulb text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  To je to!
                </h5>
                <p className="mt-2 mb-4 text-gray-500">Sretno s prodajom!</p>
              </div>
            </div>
            <div id="form"></div>
          </div>
        </section>
        <section className="relative block py-24 lg:pt-0 bg-gray-900">
          <NewPost categories={categories} />
        </section>
      </main>
    </>
  );
};

export default Home;
