export const five = [
  { Naziv: "", Mjera: "", Cijena: "" },
  { Naziv: "", Mjera: "", Cijena: "" },
  { Naziv: "", Mjera: "", Cijena: "" },
  { Naziv: "", Mjera: "", Cijena: "" },
  { Naziv: "", Mjera: "", Cijena: "" },
];
export const ten = [
  { Naziv: "", Mjera: "", Cijena: "" },
  { Naziv: "", Mjera: "", Cijena: "" },
  { Naziv: "", Mjera: "", Cijena: "" },
  { Naziv: "", Mjera: "", Cijena: "" },
  { Naziv: "", Mjera: "", Cijena: "" },
  { Naziv: "", Mjera: "", Cijena: "" },
  { Naziv: "", Mjera: "", Cijena: "" },
  { Naziv: "", Mjera: "", Cijena: "" },
  { Naziv: "", Mjera: "", Cijena: "" },
  { Naziv: "", Mjera: "", Cijena: "" },
];
