import React from "react";

const Footer = () => {
  return (
    <footer className="relative bg-gray-300 pt-2 pb-6">
      <div className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none ">
        {/*   <svg
        className="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          className="text-gray-300 fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg> */}
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-gray-300 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div className="container mx-auto px-4">
        <hr className="my-6 border-gray-400" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="container max-w-3xl mx-auto px-4">
            <div className="flex flex-wrap justify-center text-center mb-12">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-2xl font-semibold">Projekt omogućili</h2>
              </div>
            </div>
            <div className="flex justify-between flex-wrap">
              <div className="w-1/3 md:w-3/12 lg:w-3/12 lg:mb-0 mb-12 self-center">
                <div className="px-3">
                  <a
                    href="http://pitomaca.hr/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="..."
                      src={require("../../assets/img/ptc_logo_novo.png")}
                      className="w-full sm:w-full sm:max-w-full mx-auto"
                      style={
                        {
                          /* maxWidth: 250 */
                        }
                      }
                    />
                  </a>
                </div>
              </div>
              <div className="w-1/3 md:w-2/12 lg:w-2/12 lg:mb-0 mb-12 self-center">
                <div className="px-3">
                  <a
                    href="http://parpar.hr/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="..."
                      src={require("../../assets/img/parpar_logo.png")}
                      className="w-1/2 sm:w-full sm:max-w-full  mx-auto"
                      /* style={{ maxWidth: 125 }} */
                    />
                  </a>
                </div>
              </div>
              <div className="w-1/3 md:w-3/12 lg:w-3/12 lg:mb-0 mb-12 self-center">
                <div className="px-3">
                  <a
                    href="https://draftdoo.hr/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="..."
                      src={require("../../assets/img/draft.svg")}
                      className="w-full sm:w-full sm:max-w-full mx-auto"
                      /* style={{ maxWidth: 250 }} */
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
