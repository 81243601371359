import React from "react";

export const Select = ({
  register,
  label,
  name,
  id,
  options,
  defaultValue,
  ...rest
}) => (
  <>
    <label
      className="block uppercase text-gray-700 text-xs font-bold mb-2"
      htmlFor={id}
    >
      {label}
    </label>
    <div className="relative">
      <select
        name={name}
        defaultValue={defaultValue}
        className="block appearance-none w-full placeholder-gray-400 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded shadow focus:shadow-outline leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        id={id}
        ref={register}
        {...rest}
      >
        {options.length !== 0 ? (
          options.map((item) => (
            <option key={item.Naziv} value={item.Naziv}>
              {item.Naziv}
            </option>
          ))
        ) : (
          <option>...</option>
        )}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
  </>
);
